export const baseUrl =
  process.env.NODE_ENV === "production"
    ? "https://api.psychphransisco.com"
    : "http://localhost:3003";

const processResponseServer = (res) => {
  if (res.ok) {
    return res.json();
  }

  return Promise.reject(`Error: ${res.status}`);
};

function logoutEraseFile() {
  return fetch(`${baseUrl}/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(processResponseServer);
}

function runPythonScript() {
  return fetch(`${baseUrl}/run-script`, {
    method: "POST",
    headers: {
      "Content-Type": "application / json",
    },
  }).then(processResponseServer);
}

function getInfoScript() {
  return fetch(`${baseUrl}/get-info`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(processResponseServer);
}

function terminateScript() {
  return fetch(`${baseUrl}/kill-script`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(processResponseServer);
}

async function generatedGoogleSheet(values, userEmail) {
  try {
    const response = await fetch(`${baseUrl}/add-data-to-googlesheet`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ values, userEmail }),
    });
    const responseData = await response.json();
    console.log("Response from backend:", responseData);
    return responseData;
  } catch (error) {
    console.error("Error:", error);
  }
}

export {
  runPythonScript,
  getInfoScript,
  terminateScript,
  logoutEraseFile,
  generatedGoogleSheet,
};
