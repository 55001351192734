import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Header from "../Header/Header.js";
import Main from "../Main/Main.js";
import Authentication from "../Authentication/Authentication.js";
import Information from "../Information/Information.js";
import {
  runPythonScript,
  getInfoScript,
  terminateScript,
  logoutEraseFile,
  generatedGoogleSheet,
} from "../../utils/fetch.js";
import "./App.css";
import ProtectedRoute from "../ProtectedRoute.js";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [info, setInfo] = useState([]);
  const [runInfo, setRunInfo] = useState([]);
  const navigate = useNavigate();
  const [runRendering, setRunRendering] = useState(false);
  const [getRendering, setGetRendering] = useState(false);
  const [terminateRendering, setTerminateRendering] = useState(false);
  const [googleSheetInfo, setGoogleSheetInfo] = useState([]);
  const [googleSheetDiv, setGoogleSheetDiv] = useState(false);
  const handleRunRendering = () => {
    setRunRendering((state) => !state);
  };
  const handleGetRendering = () => {
    setGetRendering((state) => !state);
  };
  const handleTerminateRendering = () => {
    setTerminateRendering((state) => !state);
  };

  /////////////// pasword /////////////////
  const secretPassword = process.env.REACT_APP_SECRET_PASSWORD;
  const userEmail = "psychphransiscobounce@gmail.com";

  ///////////// functions for running python scripts //////////////////

  const handleRunScript = () => {
    handleRunRendering();
    runPythonScript()
      .then((res) => {
        console.log(res.output);
        setRunInfo(res.output);
      })
      .catch((err) => console.error(err, "error line 17 app.js"))
      .finally(handleRunRendering);
  };

  const handleInfoScript = () => {
    handleGetRendering();
    getInfoScript()
      .then((res) => {
        console.log(res.output);
        setInfo(res.output);
        //handlesGoogleSheet();
        generatedGoogleSheet(res.output, userEmail)
          .then((res) => {
            console.log(res, "info for google sheet");
            setGoogleSheetInfo(res.data);
            setGoogleSheetDiv(true);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err, "line 25 in App.js"))
      .finally(handleGetRendering);
  };

  const handleTerminateScript = () => {
    handleTerminateRendering();
    terminateScript()
      .then((res) => {
        console.log(res.output);
      })
      .catch((err) => console.error(err, "error on line 33 in App.js"))
      .finally(handleTerminateRendering);
  };

  ////////////// function for handling password success or failure and sending PI key to backend //////////

  const handlingPasscode = (password) => {
    if (password === secretPassword) {
      setLoggedIn(true);
      navigate("/main");
    } else {
      console.log("wrong password");
      return 0;
    }
  };

  /////////////////////////////// handles google sheet creation and generation ///////////////////////////////////////

  // const handlesGoogleSheet = () => {
  //   generatedGoogleSheet(info, userEmail)
  //     .then((res) => {
  //       console.log(res, "info for google sheet");
  //       setGoogleSheetInfo(res.data);
  //       setGoogleSheetDiv(true);
  //     })
  //     .catch((err) => console.error(err));
  // };

  ////// function for logging out of application ////////

  const logout = () => {
    setLoggedIn(false);
    setGoogleSheetDiv(false);
    logoutEraseFile()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.error(err, "line 118 App.js"));
  };

  useEffect(() => {
    logout();
  }, []);

  return (
    <div className="app">
      <Routes>
        <Route
          path="/login"
          element={<Authentication onSubmitPasscode={handlingPasscode} />}
        />
        <Route
          path="/main"
          element={
            <ProtectedRoute loggedIn={loggedIn}>
              <div className="app__loggedin">
                <Header />
                <Main
                  runScript={handleRunScript}
                  infoScript={handleInfoScript}
                  terminateScript={handleTerminateScript}
                  logout={logout}
                  info={info}
                  runInfo={runInfo}
                  runRendering={runRendering}
                  getRendering={getRendering}
                  terminateRendering={terminateRendering}
                  //googleSheet={handlesGoogleSheet}
                  googleSheetInfo={googleSheetInfo}
                  googleSheetDiv={googleSheetDiv}
                />
              </div>
              <Information />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </div>
  );
}

export default App;
