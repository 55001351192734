import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Main.css";

const Main = ({
  runScript,
  infoScript,
  terminateScript,
  logout,
  info,
  runInfo,
  getRendering,
  runRendering,
  terminateRendering,
  //googleSheet,
  googleSheetInfo,
  googleSheetDiv,
}) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const [buttonClicked, setButtonClicked] = useState("");

  const renderInformation = (result) => {
    if (typeof result !== "string") {
      console.log("info not a string ");
      return [];
    }
    return String(result)
      .split(/(?=\nSymbol)/)
      .map((group, index) => (
        <div className="main__info-group" key={index}>
          {group.split("\n").map((x, i) => (
            <div key={i}>{x}</div>
          ))}
        </div>
      ));
  };

  const runScriptButton = () => {
    setButtonClicked("RunInfo");
    runScript();
  };

  const runInfoButton = () => {
    setButtonClicked("ShowInfo");
    infoScript();
    //googleSheet();
  };

  return (
    <div className="main">
      <div className="main__header">
        <div className="main__buttons">
          <button
            className="main__button main__button-start"
            onClick={runScriptButton}
            disabled={runRendering}
          >
            {runRendering ? "Getting..." : "Past Week Info"}
          </button>
          <button
            className="main__button main__button-info"
            onClick={runInfoButton}
            disabled={getRendering}
          >
            {getRendering ? "Getting Info..." : "Get Info"}
          </button>
          <button
            className="main__button main__button-end"
            onClick={terminateScript}
            disabled={terminateRendering}
          >
            {terminateRendering ? "Terminating..." : "Terminate"}
          </button>
        </div>
        <button
          className="main__logout main__button"
          type="button"
          onClick={handleLogout}
        >
          Log out
        </button>
      </div>
      <div
        className={
          googleSheetDiv ? "main__googlesheet" : "main__googlesheet-none"
        }
      >
        <p>Google Sheet Link: </p>
        <a className="main__googlesheet-url">
          {googleSheetInfo?.info?.spreadsheetUrl}
        </a>
      </div>
      <div className="main__info">
        {buttonClicked === "ShowInfo"
          ? renderInformation(info)
          : renderInformation(runInfo)}
      </div>
    </div>
  );
};

export default Main;
