import React, { useState } from "react";
import "./Authentication.css";
import { baseUrl } from "../../utils/fetch";

const Authentication = ({ onSubmitPasscode }) => {
  const [passwordInput, setPasswordInput] = useState("");
  const [apiKey, setApiKey] = useState("");
  const handlePasswordChange = (e) => {
    setPasswordInput(e.target.value);
  };
  const handleApiKeyChange = (e) => {
    setApiKey(e.target.value);
  };
  const handleApiKey = async (apiKey) => {
    await fetch(`${baseUrl}/store-api-key`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ apiKey }),
    });
  };
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    onSubmitPasscode(passwordInput);
    handleApiKey(apiKey);
  };

  return (
    <div className="authentication">
      <form className="authentication__form" onSubmit={handlePasswordSubmit}>
        <input
          className="authentication__input"
          value={passwordInput}
          type="password"
          name="password"
          placeholder="Enter Password"
          onChange={handlePasswordChange}
          required
        />
        <input
          className="authentication__input"
          value={apiKey}
          type="text"
          name="text"
          placeholder="Enter API key"
          onChange={handleApiKeyChange}
          required
        />
        <button className="authentication__submit" type="submit">
          Go to website
        </button>
      </form>
    </div>
  );
};

export default Authentication;
